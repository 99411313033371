const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/jobs"],
    exact: true,
    component: "Jobs",
  },
  {
    path: ["/pay/:transactionId"],
    exact: true,
    component: "Pay",
  },
  {
    path: ["/privacy-policy"],
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: ["/policies/privacy"],
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: ["/partnership-pages-policy"],
    exact: true,
    component: "PartnershipPagesPolicy",
  },
  {
    path: ["/policies/partnership-pages"],
    exact: true,
    component: "PartnershipPagesPolicy",
  },
  {
    path: ["/pricing"],
    exact: true,
    component: "Pricing",
  },
  {
    path: ["*"],
    exact: true,
    component: "NotFound",
  },
];

export default routes;
