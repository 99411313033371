import { Row, Col, Image } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { FaFacebook, FaInstagram, FaPhone, FaRegEnvelope, FaTiktok, FaTwitter, FaWhatsapp } from 'react-icons/fa'

import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  FooterContainer,
} from "./styles";
import { Text } from '@chakra-ui/react';

interface SocialLinkProps {
  href: string;
  icon: React.ReactNode;
}

const Footer = ({ t }: { t: TFunction }) => {

  const SocialLink = ({ href, icon }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={href}
        aria-label={href}
      >
        {icon}
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t("Policies")}</Title>
              <Large to="/privacy-policy">{t("Privacy")}</Large>
              <Large to="/partnership-pages-policy">{t("Partnership & Pages")}</Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t("Locations")}</Title>
              <Para>St. Catherine, Jamaica</Para>
              <Para>Manchester, Jamaica</Para>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t("Contact")}</Title>
              <Para>(876) 485-6559</Para>
              <Para>hello@goshowfa.com</Para>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              {/* <Large to="/">{t("Press")}</Large> */}
              <Large to="/jobs">{t("Jobs")}</Large>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
              <Image
                src="/assets/images/showfa-logo.png"
                alt="Showfa Logo"
                height="30px"
                width="161px"
              /> 
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="tel:+18764856559"
                icon={<FaPhone size={20}/>}
              />
              <SocialLink
                href="mailto:hello@goshowfa.com"
                icon={<FaRegEnvelope size={20}/>}
              />
              <SocialLink
                href="https://twitter.com/goshowfa"
                icon={<FaTwitter size={20}/>}
              />
              <SocialLink
                href="https://www.tiktok.com/@goshowfa"
                icon={<FaTiktok size={20}/>}
              />
              <SocialLink
                href="https://www.facebook.com/goshowfa"
                icon={<FaFacebook size={20}/>}
              />
              <SocialLink
                href="https://www.instagram.com/goshowfa"
                icon={<FaInstagram size={20}/>}
              />
              <SocialLink
                href="https://wa.me/+18764856559"
                icon={<FaWhatsapp size={20}/>}
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
      <Extra>
        <Container>
          <Row>
          <Text>© {new Date().getFullYear()} Showfa Express Limited. All rights reserved</Text>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
